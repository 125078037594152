
import React, { useState } from "react";
import {
  Box,
  makeStyles,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import "../components/Layout/layout.scss";
import "../styles/form.scss";
import "../styles/footer.scss";
import "../styles/messageConfirm.scss";
import Layout from "../components/Layout";

import label2bg from "../images/label2bg.png";
import labelRiverside from "../images/rivesideBg.jpg";
import { Helmet } from "react-helmet";
import comp1 from "../images/compressed/comp1.png";
import comp2 from "../images/compressed/comp2.png";

const useStyles = makeStyles((theme) => ({
  videoList: {
    height: "66vh",
    overflowY: "scroll",
  },
  button: {
    color: "#fff",
    background: "#0000003b",
    padding: "20px",
    margin: "10px",
    display: "block",
    justifyContent: "start",
  },
  img: {
    width: "40px",
    height: "fit-content",
    marginRight: "1rem",
  },
  formControl: {
    border: "1px solid #fff",
    marginBottom: theme.spacing(1),
    minWidth: 120,
    lineHeight: "3rem",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    color: "#fff",
    padding: "0 10px",
    top: "6px",
  },
  icon: {
    color: "#fff",
    top: "10px",
  },
  lineHeight: {
    "& p": {
      lineHeight: "1.5rem",
      [theme.breakpoints.up("sm")]: {
        fontSize: "1vw",
      },
    },
  },
}));

const artistName = "Pantano Music";

const videoData = () => {
  return [

    {
      name: "Breathe",
      label: "Live @ Riverside studios Berlin",
      image: labelRiverside,
      iframeUrl: "https://www.youtube.com/embed/qxQdfS2Ja1w",
    },
    {
      name: "Joe",
      label: "Live @ Riverside studios Berlin",
      image: labelRiverside,
      iframeUrl: "https://www.youtube.com/embed/zoLwJvMPldM",
    },
    {
      name: "Toxic City",
      label: "Live @ Riverside studios Berlin",
      image: labelRiverside,
      iframeUrl: "https://www.youtube.com/embed/sZcm6EhZKKo",
    },
    {
      name: "Motion animation - Riverside studios Berlin",
      image: comp2,
      iframeUrl: "https://www.youtube.com/embed/zmeEGvRP1Zc",
    },
    {
      name: "Slut shamming & suggar daddies",
      image: label2bg,
      iframeUrl: "https://www.youtube.com/embed/5BpmwQDjTYQ",
    },
    {
      name: "Faggeto Beach",
      iframeUrl: "https://www.youtube.com/embed/-kc1PzGQvoM",
      image: label2bg,
    },
    {
      name: "Boomgaardstraat",
      iframeUrl: "https://www.youtube.com/embed/_mAJGc9RJJM",
      image: label2bg,
    },
    {
      name: "How to lose more after losing everything",
      iframeUrl: "https://www.youtube.com/embed/EA9iYLUxd8I",
      image: label2bg,
    },
  ];
};

function MetaTags() {
  return (
    <Helmet>
      <title>Videos - Pantano Music</title>
      <meta name="description" content="Watch to Pantano music's videos" />
      <meta property="og:title" content="Videos - Pantano Music" />
      <meta
        property="og:description"
        content="Watch to Pantano music's videos"
      />
      <meta property="og:image" content={comp1} />
      <meta property="og:url" content="https://www.pantanomusic.com/videos" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

const Videos = () => {
  const classes = useStyles();
  const theme = useTheme();

  const mobileBreakPoint = useMediaQuery(theme.breakpoints.up("md"));

  const [songVideo, setSongVideo] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setSongVideo(event.currentTarget.value);
    handleClose();
  };

  const VideoList = () => {
    return (
      <div className={classes.videoList}>        
        {videoData().map((video, id) => (
          <MenuItem
            value={id}
            key={video.iframeUrl}
            onClick={handleChange}
            className={classes.button}
          >
            <Box display="flex" alignItems="center">
              {video.image && (
                <img className={classes.img} alt="EP" src={video.image} />
              )}
              <div className={classes.lineHeight}>
                <p style={{ textAlign: "left" }}>
                  {video.name} {video.label && `(${video.label})`}
                </p>
                <p style={{ textAlign: "left" }}>{artistName}</p>
              </div>
            </Box>
          </MenuItem>
        ))}
      </div>
    );
  };

  const MobileVideoList = () => {
    return (
      <>
        <FormControl className={classes.formControl}>
          <InputLabel id="videoMobileSelectLabel" className={classes.label}>
            Select a song's video
          </InputLabel>
          <Select
            labelId="videoMobileSelectLabel"
            id="videoMobileSelect"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            // value={songVideo}
            // onChange={handleChange}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            <VideoList />
          </Select>
        </FormControl>
      </>
    );
  };

  return (
    <Layout>
      <MetaTags />
      <h1>
        <span className="hidden">Watch our </span>Videos
      </h1>
      <Box
        display="flex"
        justifyContent="space-evenly"
        flexDirection={mobileBreakPoint ? "row" : "column"}
        width="100%"
      >
        {mobileBreakPoint ? <VideoList /> : <MobileVideoList />}

        {videoData()[songVideo] && (
          <iframe
            width={mobileBreakPoint ? "853" : "100%"}
            height="505"
            src={`${videoData()[songVideo].iframeUrl}?autoplay=1`}
            title={videoData()[songVideo].name}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )}
      </Box>
    </Layout>
  );
};

export default Videos;
